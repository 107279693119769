<template>
  <div>
    <van-nav-bar
      title="进货计划"
      left-text="返回"
      right-text="新增"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickAdd"
    />
    <div style="margin-bottom: 10px">
      <van-search
        v-model="searchParam.storeName"
        show-action
        label="商品名"
        placeholder="请输入商品名关键词"
        @search="onSearch"
        @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">筛选</div>
        </template>
      </van-search>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
    <div class="van-coupon" v-for="item in dataList" :key="item.plan.uuid" :name="item.plan.uuid">
      <div style="margin-bottom: 9px;">
        <van-row>
          <van-col span="12">
            <div style="height: 20px; padding-top: 5px; justify-content: center; align-items: center;">
              <span style="font-size: 16px; font-weight: 500; color: #323233; margin-left: 10px">{{ item.plan.factoryName }}</span>
            </div>
          </van-col>
          <van-col span="12">
            <div
              style="height: 20px; padding-top: 5px; text-align: right; justify-content: center; align-items: center;">
              <span style="font-size: 13px; font-weight: 400; color: #323233; position: relative; top: -8px;">{{ item.plan.createTime }}</span>
              <!--              <van-icon name="delete-o" color="#ee0a24" size="25px" style="margin-right: 5px" @click="deleteOrder()"/>-->
              <van-icon name="more-o" color="#1989fa" size="25px" style="margin-right: 5px; margin-left: 5px;" @click="toPurchasePlanInfo(item.plan.uuid)"/>
            </div>
          </van-col>
        </van-row>
      </div>

      <div style="padding-left: 8px; padding-right: 8px; margin-top: 5px; margin-bottom: 5px;"
           v-for="(subItem, index) in item.purchaseProducts" :key="index" :name="subItem.storeName">
        <purchaseProductInfo
          :product="subItem"
          @infoEditSku="purchaseClickSku"
          @infoForDelivery="onClickPurchaseSkuDelivery"
          @deletedData="onDeleteProduct"
          @placeData="onPlaceProduct"
          @onRefresh="onRefresh"
        ></purchaseProductInfo>
      </div>
    </div>
      </van-list>
    </van-pull-refresh>
    <purchaseDelivery ref="purchaseDelivery"
                      @deleteSkuDeliveryData="deleteSkuDeliveryData"></purchaseDelivery>
    <van-popup position="bottom" :style="{ height: '90%' }" v-model="searchPopupShow">
      <van-field v-model="searchParam.storeName" label="商品名" placeholder="请输入商品名"/>
      <van-field v-model="searchParam.factoryName" label="厂家名" placeholder="请输入厂家名"/>
      <van-field name="switch" label="只看未完成">
        <template #input>
          <van-switch v-model="searchParam.onlySeeUnfinished" size="20" />
        </template>
      </van-field>
      <div style="text-align: center;">
        <van-button style="margin-top: 20px; width: 80%" size="large" type="primary" @click="onPopupSearch">搜索</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {Dialog, Field, List, NavBar, Notify, Popup, PullRefresh, Search, Switch} from 'vant';

import * as adminOrderService from "@/api/admin/order";
import * as purchaseService from "@/api/admin/purchase";
import purchaseProductInfo from "@/components/purchase/purchaseProductInfo.vue"
import PurchaseDelivery from "@/components/purchase/purchaseDelivery.vue";
import SearchForDebt from "@/view/order/searchForDebt.vue";

export default {
  components: {
    PurchaseDelivery,
    purchaseProductInfo,
    [Popup.name]: Popup,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Switch.name]: Switch,
  },
  name: "PurchaseList",
  data() {
    return {
      searchParam: {
        storeName: '',
        onlySeeUnfinished: true
      },
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      searchParamValue: '',
      searchPopupShow: false,
    };
  },
  created() {
    console.log('this.$route.query.factoryUuid', this.$route.query.factoryUuid)
    if (this.$route.query.factoryUuid) {
      this.searchParam.factoryUuid = this.$route.query.factoryUuid
      this.searchParamValue = '查找' + this.$route.query.factoryUuid + '厂家'
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickAdd() {
      this.$router.push({path: '/purchase'})
    },
    onSearch() {
      // this.searchParam.factoryName = this.searchParamValue
      this.searchParam.onlySeeUnfinished = false
      this.onRefresh()
    },
    onSearchClear() {
      this.searchParam.factoryUuid = null
      this.searchParam.factoryName = null
      this.searchParam.address = null
      this.searchParam.orderDatetime = null
      this.searchParam.onlySeeUnfinished = true
      this.onRefresh();
    },
    showPopup() {
      this.searchPopupShow = true
    },
    purchaseClickSku(data) {
      purchaseService.editSku(data).then(res => {
        if (res.status === 200) {
          this.$toast("修改成功")
        }
      })
    },
    toPurchasePlanInfo(uuid) {
      this.$router.push({path: '/purchase', query: {purchasePlanUuid: uuid}})
    },
    deleteOrder(orderId) {
      Dialog.confirm({
        title: '确认删除此订单吗？',
        message: '删除此订，无法找回！',
      })
        .then(() => {
          adminOrderService.deleteAccountOrder(orderId).then(res => {
            if (res.status === 200) {
              Notify({type: 'success', message: '删除成功'});
              this.onRefresh()
            } else {
              Notify({type: 'danger', message: '删除失败'});
            }
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      purchaseService.getPurchaseList(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    onClickPurchaseSkuDelivery(data) {
      // this.purchaseDelivery = {
      //   purchasePlanUuid: data.purchasePlanUuid,
      //   productId: data.productId,
      //   sku: data.sku
      // }
      // this.$refs.purchaseDelivery.loadData()
      const postData = {
        purchasePlanUuid: data.purchasePlanUuid,
        productId: data.productId,
        sku: data.sku
      }
      this.$refs.purchaseDelivery.loadData(postData)
      this.$refs.purchaseDelivery.isPopupShow = true
    },
    onDeleteProduct(data) {
      this.dataList.forEach(i => {
        i.purchaseProducts = i.purchaseProducts.filter(t => t.storeName !== data.storeName)
      })
    },
    onPlaceProduct(data) {
      this.dataList.forEach(it => {
        if (it.purchaseProducts === undefined) {
          return
        }
        it.purchaseProducts.forEach(subI => {
          if (data.purchasePlanUuid === subI.purchasePlanUuid && data.productId === subI.productId) {
            subI.status = 1
          }
        })
      })
    },
    deleteSkuDeliveryData(data) {
      console.log('deleteSkuDeliveryData ', data)
      this.$refs.purchaseDelivery.isPopupShow = false
      this.onRefresh()
    },
    // onChangeSwitch() {
    //   this.searchPopupShow = false
    //   this.onRefresh()
    // },
    onPopupSearch() {
      this.searchPopupShow = false
      this.onRefresh()
    }
  },
};
</script>

<style lang="less">
//@grid-item-content-padding: #66A0FF
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.order-tag-margin-left {
  margin-left: 2px
}
</style>
